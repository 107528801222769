import { Goal, Club, Student, CreateGoalRequest, ClubDate } from "@/types";
import { axiosInstance } from "../clients";

async function listMemberGoalsByClub(club_id: Club["id"], student_id: Student["id"]): Promise<{ data: { goals: Goal[] }; meta: { status: 200 } }> {
  return (await axiosInstance.get(`/v1/clubs/${club_id}/students/${student_id}/goals`)).data;
}

async function listAllGoalsInClub(club_id: Club["id"], status: string, category?: string): Promise<{ data: { goals: Goal[] }; meta: { status: 200 } }> {
  return (
    await axiosInstance.get(`/v1/clubs/${club_id}/students/goals`, {
      params: {
        status,
        category,
      },
    })
  ).data;
}

async function createGoal(club_id: Club["id"], student_id: Student["id"], goal: CreateGoalRequest): Promise<{ data: { goal: Goal }; meta: { status: 200 } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/goals`, goal)).data;
}

async function updateGoal(club_id: Club["id"], student_id: Student["id"], goal_id: Goal["id"], data: CreateGoalRequest): Promise<{ data: { goal: Goal }; meta: { status: 200 } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/goals/${goal_id}`, data)).data;
}

async function updateGoalProgress(
  club_id: Club["id"],
  student_id: Student["id"],
  goal_id: Goal["id"],
  data: {
    progress: number;
    session_date_id: ClubDate["id"];
    goal_id: Goal["id"];
  }
): Promise<{ data: { goal: Goal }; meta: { status: 200 } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/goals/${goal_id}/progress`, data)).data;
}

export default {
  listMemberGoalsByClub,
  updateGoalProgress,
  listAllGoalsInClub,
  createGoal,
  updateGoal,
};
